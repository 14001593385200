<template>
  <CRow>
    <CCol :sm="12">
      <CCard>

        <CCardHeader>
          <h5 v-if="benutzer">
            <CIcon size="lg" name="cil-plus"/>
            Passwort für <em>Benutzer {{benutzer.name}}</em>
          </h5>
        </CCardHeader>

        <CCardBody>
          <CForm @submit.prevent="changePassword" autocomplete="off">

            <input-password v-model="form.password" :vld="v$.form.password" label="Passwort"/>
            <input-submit label="Passwort ändern" :vld="v$"/>
          </CForm>

          <FormError v-if="formError" :errormessage="formError"/>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { required } from '@vuelidate/validators'
import FormError from '@/components//form-elements/Error'
import inputPassword from '@/components/form-elements/Password'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'app-user-passwort',
  components: {
    inputPassword,
    inputSubmit,
    FormError
  },
  props: {
    uuid: [Number, String]
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  validations () {
    return {
      form: {
        password: {
          required
        }
      }
    }
  },
  data () {
    return {
      formError: null,
      benutzer: null,
      form: {
        password: ''
      }
    }
  },
  mounted () {
    this.loadMitarbeiter()
  },
  methods: {
    changePassword () {
      this.axios.patch('/user/password/change/' + this.uuid, {
        plainPassword: this.form.password
      }).then(() => {
        this.toast.success('Das Passwort wurde geändert.')
        this.benutzer = null
        this.formError = null
        this.plainPassword = ''
        this.$router.push({ path: '/benutzer/uebersicht' })
      }).catch((error) => {
        this.formError = error.response.data
      })
    },
    loadMitarbeiter () {
      if (this.uuid) {
        this.axios.get('/user/get/user/' + this.uuid)
          .then((response) => {
            this.benutzer = response.data[0]
          })
      }
    }
  }
}
</script>
